<!-- When upgrading to Vue3: swap "value" and "modelValue" -->
<template>
    <v-checkbox required hide-details="auto" v-model="modelValue" class="mt-3 pt-0"
        :rules="getRequiredRules('U moet akkoord gaan met de algemene voorwaarden')">
        <template v-slot:label>
            <span class="required_star">Ik ga akkoord met de <a :href="eulaRoute" @click.stop target="_blank">algemene
                    voorwaarden</a></span>
        </template>
    </v-checkbox>
</template>
<script>
import { getRequiredRules } from '@/shared/utils/inputRulesUtils.js';
import RouteNames from "@/router/RouteNames";

export default {
    name: "EulaCheckbox",
    components: {

    },
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: Boolean
    },
    emits: ['input'],
    data() {
        return {
          eulaRoute: this.$router.resolve({ name: RouteNames.EULA }).href,
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        }
    },
    methods: {
        getRequiredRules
    }
}
</script>