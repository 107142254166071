<template>
  <div class="radio-button-form">
      <v-radio-group v-model="internalPaymentMethod" @change="changeEvent" row :rules="getRequiredRules('Gelieve een betaalmethode te selecteren')">
        <label class="surrounding-label required_star_last_child" v-for="opt in options" v-bind:key="opt.value">
          <v-radio v-bind:key="opt.value" class="hidden-radio-button" display="none" :value="opt.value" :name="opt.groupName"/>
          <img :src="opt.image" width="50px" height="50px" :alt="opt.value" />
        </label>
    </v-radio-group>
  </div>
</template>

<script>
import {getRequiredRules} from "@/shared/utils/inputRulesUtils";

export default {
  name: "PaymentSelection",
  props: {
    paymentMethod: String,
  },
  data() {
    return {
      internalPaymentMethod: null,
      options: [
      {
          value: "bancontactmrcash",
          groupName:"paymentButtons",
          image: require('@/assets/payment_methods/bancontact_logo.svg')
      },{
          value: "payconiq",
          groupName:"paymentButtons",
          image: require('@/assets/payment_methods/payconiq_logo.svg')
      },{
          value: "KBCPaymentButton",
          groupName:"paymentButtons",
          image: require('@/assets/payment_methods/kbc_logo.png')
      },{
          value: "belfius",
          groupName:"paymentButtons",
          image: require('@/assets/payment_methods/belfius_logo.png')
      },
      ]
    }
  },
  methods: {
    getRequiredRules,
    changeEvent() {
      this.$emit('input', this.internalPaymentMethod);
    }
  },
}
</script>

<style lang="scss" scoped>
.radio-button-form label {
  margin-right: 20px;
  display: inline-block;
  cursor: pointer;
}

.surrounding-label {
  img {
    display: block;
    border: 2px solid $border-color;
    border-radius: 5px;
    position: relative;
    transition: all 0.25s linear;
    object-fit: contain;

    &:before {
      content: '';
      position: absolute;
      left: 5px;
      top: 50%;
      -webkit-transform: translatey(-50%);
      transform: translatey(-50%);
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $cultured-gray;
      transition: all 0.25s linear;
    }
  }
  .v-item--active + img {
    background-color: white;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    color: $spring-green;
    border-color: $spring-green;

    &:before {
      background-color: $spring-green;
    }
  }
}
</style>